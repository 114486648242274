iframe#webpack-dev-server-client-overlay{display:none!important}
.calendly-overlay{background:transparent!important;}
.persona-widget__overlay{background:transparent!important;}
#maven-chat-widget>div{z-index:1500!important;bottom:1.5rem!important;}

@font-face {
    font-family: 'Gabarito';
    src: url('components/fonts/Gabarito.ttf') format('truetype');
    font-weight: 400 900;
    font-style: normal;
  }
  